<template>
  <a-table
    :data-source="video_list"
    :columns="columns"
    :loading="loading"
    :pagination="pagination"
    :scroll="{
      scrollToFirstRowOnChange: true,
      x: true,
    }"
    @change="handleTableChange"
  >
    <template #title>
      <a-typography-title :level="5">
        Videos Recorded: &nbsp;
        <a-tag color="blue">{{ videosCount }}</a-tag>
      </a-typography-title>
    </template>

    <template #headerCell="{ title }">
      <span class="d-flex justify-content-center">{{ title }}</span>
    </template>

    <template
      #customFilterDropdown="{
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
        column,
      }"
    >
      <div style="padding: 8px">
        <a-input
          ref="searchInput"
          :placeholder="`Search ${column.dataIndex}`"
          :value="selectedKeys[0]"
          style="width: 188px; margin-bottom: 8px; display: block"
          @change="
            (e) => setSelectedKeys(e.target.value ? [e.target.value] : [])
          "
          @pressEnter="handleSearch(selectedKeys, confirm, column.dataIndex)"
        />
        <a-button
          type="primary"
          size="small"
          style="width: 90px; margin-right: 8px; display: inline-flex"
          @click="handleSearch(selectedKeys, confirm, column.dataIndex)"
        >
          <template #icon>
            <SearchOutlined />
          </template>
          Search
        </a-button>
        <a-button
          size="small"
          style="width: 90px"
          @click="handleReset(clearFilters)"
        >
          Reset
        </a-button>
      </div>
    </template>

    <template #customFilterIcon="{ filtered }">
      <search-outlined :style="{ color: filtered ? '#108ee9' : undefined }" />
    </template>

    <template #bodyCell="{ text, record, column }">
      <span v-if="column.dataIndex === 'thumbnail_path'" class="d-flex">
        <img
          v-if="record.isThumbnailExist"
          class="clickable"
          @click="emit('handlePlay', record)"
          :src="thumbnailUrl(record)"
          :alt="getFileName(record.fileName)"
          width="140"
          height="124"
          @error="
            $emit('updateVideo', record.fileName, { isThumbnailExist: false })
          "
        />
        <NotFoundImage v-else :alt-url="thumbnailUrl(record)" />
        <!-- <loading-outlined v-else class="m-auto" /> -->
      </span>

      <span
        v-if="column.dataIndex === 'fileName'"
        class="d-flex align-items-center"
      >
        <cloud-download-outlined
          class="mr-3"
          v-if="isLabeler"
          :style="{ color: record.status || 'grey' }"
        />
        {{ getFileName(text) }}
      </span>

      <span v-if="column.dataIndex === 'labelled'">
        <a-progress
          type="circle"
          :percent="getPercent(text)"
          status="normal"
          :width="50"
        />
      </span>

      <span v-if="column.dataIndex === 'details'">
        <a-tag color="blue">Resolution: {{ record.resolution }}</a-tag>

        <a-tag color="blue">FPS: {{ record.fps }}</a-tag>
        <a-tag :color="getHumanDetectionStatus(record.is_human_in_video)">
          <template #icon>
            <i
              class="bi bi-file-person-fill"
              :class="{
                'text-secondary': !record.is_human_in_video,
              }"
            />
          </template>
        </a-tag>
      </span>

      <span v-if="column.dataIndex === 'operation'">
        <a-button
          type="primary"
          block
          @click="emit('annotateVideo', record)"
          :loading="record.annotatingVideo"
          >Annotate
          <template #icon>
            <highlight-outlined />
          </template>
        </a-button>
        <!-- <a-button
          type="primary"
          block
          class="mt-2"
          @click="emit('trimVideo', record)"
          >Trim
          <template #icon>
            <scissor-outlined />
          </template>
        </a-button> -->
        <a-button
          type="primary"
          :disabled="
            !(record.localURL || record.fileURL) || record.availableLocally
          "
          block
          class="mt-2"
          @click="accelerateVideo(record)"
          >Accelerate Video
          <template #icon>
            <DownloadOutlined />
          </template>
        </a-button>
      </span>

      <span v-if="column.dataIndex === 'isProtectedVideo' && !isLabeler">
        <a-switch
          :checked="record.isProtectedVideo"
          @change="(value) => updateVideoProtection(value, record)"
          size="small"
        />
      </span>

      <span v-if="column.dataIndex === 'created_at'">
        <small>
          {{ formatDate(text) }}
        </small>
      </span>

      <span v-if="column.dataIndex === 'actions'">
        <div class="d-flex flex-column">
          <!--          <a-button-->
          <!--            v-if="isLabeler"-->
          <!--            type="primary"-->
          <!--            :disabled="-->
          <!--              !(record.localURL || record.fileURL) || record.availableLocally-->
          <!--            "-->
          <!--            @click="downloadVideo(record)"-->
          <!--          >-->
          <!--            Download-->
          <!--            <template #icon>-->
          <!--              <DownloadOutlined />-->
          <!--            </template>-->
          <!--          </a-button>-->

          <!--          <a-button-->
          <!--            v-if="!isLabeler"-->
          <!--            type="primary"-->
          <!--            @click="downloadFileUsingUrl(record.fileURL)"-->
          <!--          >-->
          <!--            <template #icon>-->
          <!--              <DownloadOutlined />-->
          <!--            </template>-->
          <!--          </a-button>-->

          <a-popconfirm
            okText="Yes"
            cancelText="No"
            @confirm="emit('deleteVideo', record)"
            @cancel="recordToDelete = null"
          >
            <template #title>
              <span
                >Do you want to delete the video
                <strong>{{ record.fileName }}</strong> ?<br />
                This will delete all associated labels as well.</span
              >
            </template>
            <template #icon
              ><QuestionCircleOutlined style="color: red"
            /></template>

            <a-button type="primary" danger class="mt-2" v-if="!isLabeler">
              <template #icon> <DeleteOutlined /> </template
            ></a-button>
          </a-popconfirm>
        </div>
      </span>
    </template>
  </a-table>
</template>

<script>
import {
  donwloadAllfiles,
  downloadFileUsingUrl,
  download_files,
} from '../../../shared/Helpers/downLoadFIleUsingUrl';
import dateHelper from '../../../shared/Helpers/dateHelper';
import downloadVideoFromS3 from '../../../shared/Helpers/downloadVideoFromS3';
import { url } from '@vuelidate/validators';
import { defaults } from 'lodash';
import {
  computed,
  defineComponent,
  inject,
  onMounted,
  reactive,
  ref,
  toRefs,
  watch,
  watchPostEffect,
} from 'vue';
import {
  SearchOutlined,
  DownloadOutlined,
  DeleteOutlined,
  QuestionCircleOutlined,
  LoadingOutlined,
  CloudDownloadOutlined,
  HighlightOutlined,
  ScissorOutlined,
} from '@ant-design/icons-vue';
import { useRoute } from 'vue-router';
import { roles } from 'src/config/roles-config';
import { trainingThumbnail, getThumbnailUrl } from 'src/utils/thumbnail';
import ThumbnailService from 'src/services/thumbnails';
import httpClient from 'src/service/httpClient';
import SQSServices from '@/services/sqs';
import NotFoundImage from 'src/components/shared/Components/NotFound.vue';

export default defineComponent({
  components: {
    SearchOutlined,
    DownloadOutlined,
    DeleteOutlined,
    QuestionCircleOutlined,
    LoadingOutlined,
    CloudDownloadOutlined,
    HighlightOutlined,
    ScissorOutlined,
  },
  emits: [
    'updateVideo',
    'handlePlay',
    'annotateVideo',
    'acclerateVideo',
    'trimVideo',
    'sortList',
    'deleteVideo',
    'pageChange',
    'openObjectAnnotationModal',
  ],
  props: {
    video_list: {
      type: Array,
    },
    videosCount: {
      type: Number,
    },
    taskId: {
      type: Number,
    },
    taskName: {
      type: String,
    },
    organization: {
      type: String,
    },
    sortBy: {
      type: String,
    },
    sortOrder: {
      type: String,
    },
    canDownload: {
      type: Boolean,
      default: true,
    },
    canDelete: {
      type: Boolean,
      default: true,
    },
    canUpdateProtection: {
      type: Boolean,
      default: true,
    },
    labeler: {
      type: Boolean,
      default: false,
    },
    loading: {},
    updateTaskRecord: Function,
  },

  setup(props, { emit }) {
    const currentRole = localStorage.getItem('role');
    onMounted(onMount);

    function onMount() {}
    const isThumbnailExist = ref(true);
    const queuedthumbnailGenerations = ref([]);
    const toast = inject('toast');
    const state = reactive({
      searchText: '',
      searchedColumn: '',
      pageSize: 10,
      current: 1,
    });
    const searchInput = ref();
    const { query } = useRoute();
    if (query.page) {
      handlePageChange({ current: query.page });
    }

    const isLabeler = computed(() => currentRole === roles.labeler);

    let columns = [
      {
        title: 'Thumbnail',
        dataIndex: 'thumbnail_path',
        key: 'thumbnail_path',
      },
      {
        title: 'Filename',
        dataIndex: 'fileName',
        key: 'fileName',
        sorter: !isLabeler.value,
      },
      {
        title: 'Details',
        dataIndex: 'details',
        key: 'details',
      },
      {
        title: 'Duration',
        dataIndex: 'duration',
        key: 'duration',
        sorter: !isLabeler.value,
      },
      {
        title: 'Upload Time',
        dataIndex: 'created_at',
        key: 'created_at',
        sorter: !isLabeler.value,
      },
      {
        title: 'Labeled',
        dataIndex: 'labelled',
        key: 'labelled',
        sorter: !isLabeler.value,
      },
      {
        title: 'Operation',
        dataIndex: 'operation',
        key: 'operation',
      },
      // {
      //   title: 'Protected',
      //   dataIndex: 'isProtectedVideo',
      //   key: 'isProtectedVideo',
      // },
      // {
      //   title: 'Actions',
      //   dataIndex: 'actions',
      //   key: 'actions',
      // },
    ];
    if (isLabeler.value) {
      columns = columns.filter((c) => c.dataIndex !== 'isProtectedVideo');
    }

    watch(
      () => props.taskId,
      (_) => {
        state.current = 1;
      }
    );

    watchPostEffect(() => {
      props.video_list.forEach((video) => {
        if (!video.isThumbnailExist && !isThumbnailGenerationInitiated(video)) {
          queuedthumbnailGenerations.value.push(video.id);
          handleThumbnailGeneration(video);
        }
      });
    });

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
      confirm();
      state.searchText = selectedKeys[0];
      state.searchedColumn = dataIndex;
    };

    const handleReset = (clearFilters) => {
      clearFilters();
      state.searchText = '';
    };

    const pagination = computed(() => ({
      total: props.videosCount,
      current: Number(state.current),
      pageSize: state.pageSize,
      showSizeChanger: false,
      position: ['bottomCenter'],
    }));

    function isThumbnailGenerationInitiated(video) {
      return queuedthumbnailGenerations.value.includes(video.id);
    }

    async function handleThumbnailGeneration(video) {
      const [error, data] = await generateThumbnail(video);
      if (error) {
        return;
      }
      emit('updateVideo', video.fileName, { isThumbnailExist: true });
      queuedthumbnailGenerations.value =
        queuedthumbnailGenerations.value.filter((id) => id !== video.id);
    }

    async function generateThumbnail(record) {
      const payload = {
        id: record.id,
      };
      return await ThumbnailService.generateThumbnail(payload);
    }

    function getHumanDetectionStatus(is_human_in_video) {
      if (is_human_in_video) {
        return 'success';
      }
      return 'default';
    }

    function handleTableChange(pag, filters, sorter) {
      handlePageChange(pag);
      handleSorting(sorter);
    }

    function handleSorting(sorter) {
      let { field, order } = sorter;
      if (!order) {
        field = 'id';
        order = 'ascend';
      }
      emit('sortList', { field, order });
    }

    function handlePageChange(pag) {
      const { current } = pag;
      state.current = current;
      emit('pageChange', current);
    }

    function thumbnailUrl(record) {
      const taskName = props.taskName;
      if (!taskName) return;
      const thumbnailPath = trainingThumbnail(
        record,
        taskName,
        this.organization
      );
      return getThumbnailUrl(thumbnailPath);
    }

    function getFileName(filename) {
      let name = filename?.slice(0, 30);
      if (filename?.length > 30) name += '...';
      return name;
    }

    function updateVideoProtection(value, video) {
      props.updateTaskRecord({ isProtectedVideo: value }, video);
    }

    function getPercent(text) {
      return Number(text.toFixed(1));
    }

    async function downloadVideo(video) {
      if (video.availableLocally) return;

      const baseURL =
        '/downloadVideo/' +
        String(props.organization) +
        '/' +
        props.taskId +
        '/' +
        props.taskName +
        '/' +
        String(video.fileName);
      const res = await httpClient.get(
        baseURL,
        false,
        true,
        false,
        'successful',
        'failed',
        'http://localhost:5000/'
      );
      if (res === 403) {
        toast.error('Error in downloading video. Try again later.');
        return;
      }
      console.log('done');
      toast.info(String(video.fileName) + ' download has started!');
    }

    async function accelerateVideo(video) {
      const payload = {
        queueName: 'ant-media',
        sqsMessage: {
          file_name: video.fileName,
          file_url: video.fileURL,
          task_id: video.id,
          method: 'POST',
        },
      };

      const [error, data] = await SQSServices.sendSQSMessage(payload);
      if (error) {
        console.log({ error });
        toast.error(error);
        return;
      }
      toast.success(data.Status);
    }

    return {
      emit,
      columns,
      isLabeler,
      getPercent,
      pagination,
      handleReset,
      searchInput,
      getFileName,
      handleSearch,
      queuedthumbnailGenerations,
      isThumbnailGenerationInitiated,
      handleThumbnailGeneration,
      generateThumbnail,
      isThumbnailExist,
      thumbnailUrl,
      downloadVideo,
      ...toRefs(state),
      handleTableChange,
      downloadFileUsingUrl,
      updateVideoProtection,
      formatDate: dateHelper.formatDate,
      getHumanDetectionStatus,
      accelerateVideo,
    };
  },
});
</script>

<style scoped>
td {
  color: black;
  text-align: center;

  /* width: 200px; */
}

.sort-icon {
  font-size: 12px;
  margin-right: 3px;
}

.video-play {
  width: 100px;
  height: 60px;
}

.table-header-sticky {
  background: white;
  position: sticky;
  top: 0;
  z-index: 1;
}

.cursor-p {
  cursor: pointer;
}

.table-container {
  height: 70vh;
}
</style>
